import React from "react";
import TgIcon from "../images/tg-icon.png";
import VkIcon from "../images/vk-icon.jpg";
import FbIcon from "../images/fb-icon.jpg";
import InstIcon from "../images/inst-icon.jpg";
import SkLogo from "../images/sk_logo.png";
import {Link} from "gatsby";

export function Footer() {
    return (
        <footer className="footer-bg">
            <div className="container">
                <div className="footer-content">
                    <div className="footer-line"></div>
                    <div className="footer-tel">
                    </div>
                    <div className="footer-copyright">
                        <div className="footer__rekv">
                            <span className="footer__rekv_weight">ID: 347433047</span>
                            <span className="footer__rekv_weight">Company name: DeliveryCRM</span>
                            <span className="footer__rekv_weight">Owner name: Dmitry Barychev</span>
                            <span className="footer__rekv_weight">Date of registration: 01.04.2024</span>
                            <span className="footer__rekv_weight">Address: 7742110, Israel, Ashdod, Keren-ha-Esod, 20-21</span>
                        </div>
                        <div className="nav__footer_support font_light">
                            <Link to="/policy">Положение о конфиденциальности персональных данных</Link>
                            <br />
                            <Link to="/user-agreement">Пользовательское соглашение</Link>
                        </div>
                        <div className="nav__footer_support font_light">
                            <a href="mailto:support@strekoza.ai">support@strekoza.ai</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}