import React, {useEffect, useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderImage from "../images/new-hc-img.png"
import DeliveryServiceImage from "../images/delivery-service.png"
import { Link } from "gatsby"
import { Social } from "../components/Social/Social";
import { IntegrationServices } from "../components/Integration/IntegrationServices";
import { Information } from "../components/Information/Information";
import { yandexMetric } from "../components/Functions/yandexMetric";
import { Slider } from "../components/Slider/Slider";
import { ModalSlider } from "../components/Slider/ModalSlider";

export default function Index(props) {

    const [openItem, setOpenItem] = useState(null); // хук для получения данных в слайдере

    useEffect(() =>{

        if (openItem !== null) {
            document.body.classList.add('body-scroll');
        } else {
            document.body.classList.remove('body-scroll');
        }
    }, [openItem])


    return (
        <Layout location={props.location}>
            <SEO title="Сервис информирования для интернет-магазинов"/>
            <section className="header-section">
                <div className="container header-content_padding">
                    <div className="header-content">
                        <div className="header-content__text">
                            <h2 className="header-content__title">
                                <div className="header-title__text">
                                    Настройте <br/> коммуникацию с <span className="header_customer">покупателями</span> <span className="header-content__platform"> своего интернет-магазина</span>
                                </div>
                            </h2>
                            <span className="header-content__platform">с помощью платформы контроля доставки</span>
                            <a
                                className="btn-overall header-content__btn"
                                href="http://deliverycrm.ru/register"
                                onClick={() => yandexMetric("startUsingMain")}
                            >
                                Начать использовать
                            </a>
                            <ul className="header-content__paragraph">
                                <li className="demo__item demo__fixed">
                                    <a
                                        className="btn-overall btn__fixed"
                                        href="http://deliverycrm.ru/register"
                                        onClick={() => yandexMetric("startUsingFixed")}
                                    >
                                        Начать использовать
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="header-content__img">
                            <img src={HeaderImage} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ab-test">
                <div className="container">
                    <Social />
                    <Information />
                </div>
            </section>

            <section className="bid">
                <div className="container">
                    <div className="bid-box">
                        <div className="bid-box__imposed">
                            <h2 className="bid-box__heading">Давайте увеличим выкуп посылок с наложенным платежом! <span className="bid-box__imposed_block">Срок проведения теста 2-3 недели</span></h2>
                            <a
                                className="btn-overall bid-box__btn"
                                href="http://deliverycrm.ru/register"
                                onClick={() => yandexMetric("leaveApplication")}
                            >
                                Оставить заявку
                            </a>
                        </div>
                        <div className="bid-box__money">
                            <h2 className="bid-box__heading bid-box__heading_center">Будете недовольны - вернем деньги!</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="reviews">
                <div className="container">
                    <div className="reviews-block__line">
                        <h2 className="reviews-block__line-head">Отзывы</h2>
                    </div>
                    <Slider setOpenItem={setOpenItem} />
                    <ModalSlider openItem={openItem} setOpenItem={setOpenItem} />
                </div>
            </section>

            <section className="price">
                <div className="container">
                    <div className="price-box">
                        <h2 className="price-box__head">ЦЕНА за 1 посылку <br/> от 9,90 рублей </h2>
                    </div>
                </div>
            </section>

            <section className="history">
                <div className="history-bg">
                    <div className="container">
                        <div className="history-box">
                            <IntegrationServices />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
